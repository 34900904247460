import React, { useEffect, useState } from "react";
import SingleSelectDropdown from "../SingleSelectDropdown";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { IoChevronBack, IoClose } from "react-icons/io5";
import toast from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";
import GreenTick from "../statusIndicator/greenTick";
import CrossMark from "../statusIndicator/cross";
import { getIntegerFromString } from "../../utils/getIntegerFromStr";
import ScheduleSettings from "../SchedulerSettings";
import Google_logo from "../../assets/google_logo.svg";

interface SchedulingUserInput {
  interval?: string;
  period?: string;
  minute?: number;
  days_of_week?: string[];
  hour?: number;
  day?: number;
  month?: number;
  time_zone?: string;
}

interface userInputProps {}

const initialGaqlQuery = `SELECT
                  campaign.id,
                  campaign.name,
                  ad_group.id,
                  ad_group.name,
                  ad_group_ad.ad.id,
                  ad_group_ad.ad.name,
                  segments.date,
                  metrics.impressions,
                  metrics.clicks,
                  metrics.video_views,
                  metrics.video_quartile_p100_rate
                FROM ad_group_ad;`;

const UserInputForGoogleAds: React.FC<userInputProps> = () => {
  // State for input fields
  const [loginCustomerId, setLoginCustomerId] = useState<string>("");
  const [developerToken, setDeveloperToken] = useState<string>("");
  const [configurationName, setConfigurationName] = useState<string>("");

  // State for checkboxes
  const [atomicAdsChecked, setAtomicAdsChecked] = useState<boolean>(true);
  const [warehouseChecked, setWarehouseChecked] = useState<boolean>(false);

  // State for SingleSelectDropdowns
  const [historicalSyncTimeFrame, setHistoricalSyncTimeFrame] = useState<
    string | null
  >("");

  // state for GAQL
  const [gaql, setGaql] = useState<string>(initialGaqlQuery || "");

  // State for login status
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  // for save and test button activation
  const [isSaveAndTestActive, setIsSaveAndTestActive] =
    useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  //scope
  const [scope, setScope] = useState<string>("");
  const [externalId, setExternalId] = useState<string>("");

  const navigate = useNavigate();

  // scheduler
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [schedulingUserInput, setSchedulingUserInput] =
    useState<SchedulingUserInput>({});
  const [schedulePreview, setSchedulePreview] = useState<string>("");

  useEffect(() => {
    if (schedulePreview !== "") {
      setOpenSchedule(false);
    }
  }, [schedulePreview]);

  const getAuth = async (code: string) => {
    try {
      const response = await axiosInstance.post(
        "/connector/google-ads-oauth/",
        {
          code: code,
          login_customer_id: loginCustomerId,
          configuration_name: configurationName,
          developer_token: developerToken,
        }
      );

      if (response.status === 200) {
        setExternalId(response.data.data.external_id);
        setIsAuthorized(true);
        setAuthStatus("success");
        setIsSaveAndTestActive(true);
      } else {
        toast.error("Auth Error");
        setIsAuthorized(false);
        setAuthStatus("error");
        setIsSaveAndTestActive(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Authentication Error, Please Retry");
      setIsAuthorized(false);
      setAuthStatus("error");
      setIsSaveAndTestActive(false);
    }
  };

  // Initialize Google Login
  const login = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<
        CodeResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      getAuth(tokenResponse.code);
    },
    onError: (
      error: Pick<CodeResponse, "error" | "error_description" | "error_uri">
    ) => {
      toast.error("Authentication Error, Please Retry");
      setIsAuthorized(false);
      setAuthStatus("error");
      setIsSaveAndTestActive(false);
    },
    flow: "auth-code",
    scope: scope,
    prompt: "consent",
    access_type: "offline",
  } as any);

  // with component load scope will be fetched
  useEffect(() => {
    getScope();
  }, []);

  const getScope = async () => {
    try {
      const response = await axiosInstance.get("/connector/google-ads-oauth/");

      if (response.status === 200) {
        setScope(response.data.data.scope);
      }
    } catch (error) {
      setIsAuthorized(false);
      setAuthStatus("error");
      setIsSaveAndTestActive(false);
    }
  };

  const handleAuthorizeConnection = () => {
    if (!isAuthorized && scope !== "") {
      setAuthStatus("loading");
      login();
    }
  };

  // Handle Save and Test button click
  const handleClickSaveAndTest = async () => {
    if (
      !loginCustomerId ||
      !developerToken ||
      !configurationName ||
      !historicalSyncTimeFrame ||
      !schedulingUserInput.time_zone
    ) {
      toast.error("Fill all fields");
      return;
    }

    setLoading(true);

    const requestData = {
      login_customer_id: loginCustomerId,
      developer_token: developerToken,
      query: gaql,
      configuration_name: configurationName,
      historical_data_range: getIntegerFromString(historicalSyncTimeFrame),
      external_id: externalId,
      schedule_settings: schedulingUserInput,
    };

    try {
      const response = await axiosInstance.post(
        `/connector/g-ads-save-and-test/`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Save and test successful!");
        navigate("/validate-connector", { state: { page: "googleAds" } });
      } else {
        toast.error("Error saving and testing, please try again.");
      }
    } catch (error) {
      console.error("Error saving and testing:", error);
      toast.error("Error saving and testing, please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Determine if the button should be disabled
  const isButtonDisabled =
    !loginCustomerId ||
    !configurationName ||
    !developerToken ||
    authStatus === "loading" ||
    isAuthorized;

  return (
    <div className="mb-[250px]">
      <div onScroll={(e) => e.stopPropagation()}>
        <div className="mt-4 flex flex-col">
          {/* Login Customer ID */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Login Customer ID</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={loginCustomerId}
              onChange={(e) => setLoginCustomerId(e.target.value)}
              disabled={isAuthorized}
              placeholder="Login Customer ID ..."
            />
          </div>

          {/* Configuration Name */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Configuration Name</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={configurationName}
              onChange={(e) => setConfigurationName(e.target.value)}
              disabled={isAuthorized}
              placeholder="Configuration Name ..."
            />
          </div>

          {/* Developer Token */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Developer Token</h3>
            <input
              type="text"
              className="border outline-none p-3 w-96 h-12 text-sm"
              value={developerToken}
              onChange={(e) => setDeveloperToken(e.target.value)}
              disabled={isAuthorized}
              placeholder="Developer Token ..."
            />
          </div>

          {/* Destination */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Destination</h3>
            <div className="flex items-center gap-3">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="atomic-ads"
                  className="border outline-none p-3 w-4 h-4"
                  checked={atomicAdsChecked}
                  onChange={() => setAtomicAdsChecked(!atomicAdsChecked)}
                />
                <label htmlFor="atomic-ads" className="ml-2 text-sm">
                  Atomic Ads
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="warehouse"
                  className="border outline-none p-3 w-4 h-4"
                  checked={warehouseChecked}
                  disabled
                />
                <label
                  htmlFor="warehouse"
                  className="ml-2 text-sm text-gray-500"
                >
                  Warehouse
                </label>
              </div>
            </div>
          </div>

          {/* Authorize Connection Button */}
          <div className="flex items-center gap-2 mb-6">
            <button
              className={`border-[0.75px] ${
                isButtonDisabled && authStatus !== "success"
                  ? "border-gray-300 text-gray-300"
                  : "border-[#286043] text-[#286043]"
              } text-sm font-semibold py-[6px] px-3 w-fit flex items-center`}
              onClick={handleAuthorizeConnection}
              disabled={isButtonDisabled}
            >
              {authStatus === "loading" && (
                <div className="flex gap-2 items-center">
                  <Loader />
                  Authorizing...
                </div>
              )}
              {authStatus === "success" && (
                <>
                  {/* Green tick */}
                  <GreenTick />
                  Authorized
                </>
              )}
              {authStatus === "error" && (
                <>
                  <CrossMark />
                  Authorization Failed, Retry
                </>
              )}
              {authStatus === "idle" && (
                <>
                  <img
                    src={Google_logo}
                    alt="google"
                    className="h-6 w-auto mr-1"
                  />
                  Authorize
                </>
              )}
            </button>
          </div>

          {/* GAQL Query */}
          <div className="mb-6 mt-6">
            <h3 className="text-sm font-semibold mb-4">GAQL Query</h3>
            <textarea
              className="border outline-none p-3 w-96 h-48"
              value={gaql}
              onChange={(e) => setGaql(e.target.value)}
            />
          </div>

          {/* Historical Sync Time Frame */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">
              Historical Sync Time Frame
            </h3>
            <SingleSelectDropdown
              items={["30 Days", "60 Days", "90 Days"]}
              selectedItem={historicalSyncTimeFrame}
              setSelectedItem={setHistoricalSyncTimeFrame}
              disabled={!isAuthorized}
            />
          </div>

          {/* Scheduler Setting */}
          <div className="mb-6">
            <h3 className="text-sm font-semibold mb-4">Schedule Setting</h3>
            <div className="flex gap-4 items-center">
              <button
                className={`text-sm border border-gray-300 p-2 ${
                  !isAuthorized ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setOpenSchedule((prev) => !prev)}
                disabled={!isAuthorized}
              >
                +
              </button>
              <p>{schedulePreview}</p>
            </div>
          </div>
        </div>
      </div>

      {/* scheduler popup */}
      {openSchedule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-white shadow-lg min-w-[413px] border border-gray-300"
            style={{
              boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
          >
            <div className="pt-6 px-8 pb-6">
              <div className="flex justify-end">
                <button
                  onClick={() => setOpenSchedule((prevValue) => !prevValue)}
                  className=""
                >
                  <IoClose />
                </button>
              </div>
              <ScheduleSettings
                setSchedulingUserInput={setSchedulingUserInput}
                setSchedulePreview={setSchedulePreview}
              />
            </div>
          </div>
        </div>
      )}
      <div className="fixed bottom-0 left-[100px] right-0 bg-gray-100 border-t border-gray-300 py-8 flex justify-between items-center px-6 z-20 overflow-hidden">
        <Link
          to="/connectors-library"
          className="flex items-center gap-2"
          onClick={() => setIsSaveAndTestActive(false)}
        >
          <IoChevronBack size={16} />
          <span className="text-base">Back</span>
        </Link>
        <div className="flex gap-2">
          <button
            className={`border-[0.75px] ${
              isSaveAndTestActive
                ? "border-[#286043] bg-[#286043]"
                : "bg-gray-300 border-gray-300"
            } text-white text-sm font-semibold py-[6px] px-3`}
            disabled={!isSaveAndTestActive || loading}
            onClick={handleClickSaveAndTest}
          >
            {loading ? (
              <div className="flex gap-2 items-center">
                <Loader loaderColor="text-white" />
                Saving & Testing...
              </div>
            ) : (
              "Save & Test"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInputForGoogleAds;
