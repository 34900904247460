import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import atomic_adds_logo from "../assets/atomic_adds_logo.webp";
import home_logo from "../assets/home.png";
import opportunities_logo from "../assets/opportunities_logo.png";
import campaigns_logo from "../assets/campaigns_logo.png";
import logout_logo from "../assets/log-out.png";
import connector_logo from "../assets/my_connector.png";
import connectors_lib_logo from "../assets/connectors_library.webp";
import products_logo from "../assets/products.png";
import users_logo from "../assets/users.png";
import reports_logo from "../assets/reports.png";
import rules_logo from "../assets/rule_library.png";
import taxonomy_logo from "../assets/taxonomy.png";
import create_rule_logo from "../assets/create_rule.png";
import create_taxonomy_logo from "../assets/create_taxonomy.png";
import archive_logo from "../assets/archive.png";
import admin_logo from "../assets/admin.png";
import taxonomy_rules_logo from "../assets/taxonomy_rule_logo.png";
import pacing_margin_logo from "../assets/pacingandmargin.png";
import ButtonComp from "./buttonComp";
import Cookies from "js-cookie";
import { useProfile } from "../context/ProfileContext";

interface SidebarProps {
  activePage: string;
}

const Sidebar: React.FC<SidebarProps> = ({ activePage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, toggleProfile } = useProfile();

  const handleLogout = () => {
    // Clear authentication cookies
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    // Redirect to login page
    navigate("/login");
  };

  const getHomeButton = () => (
    <ButtonComp
      btnType="Link"
      to="/products"
      btnLabel="Home"
      logo={home_logo}
    />
  );

  const renderAdminUserToggle = () => (
    <ButtonComp
      btnType="button"
      onClick={toggleProfile}
      btnLabel={isAdmin ? "Switch to User" : "Switch to Admin"}
      logo={isAdmin ? users_logo : admin_logo}
    />
  );

  const renderButtonsBasedOnPath = () => {
    const pathname = location.pathname;

    if (pathname.includes("/products") || pathname.includes("/users")) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/products"
            logo={products_logo}
            btnLabel="Manage Products"
            activePage={activePage}
          />
          {isAdmin && (
            <ButtonComp
              btnType="Link"
              to="/users"
              logo={users_logo}
              btnLabel="Manage Users"
              activePage={activePage}
            />
          )}
        </>
      );
    }

    if (pathname.includes("/reports")) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/reports"
            logo={reports_logo}
            btnLabel="Reports"
            activePage={activePage}
          />
        </>
      );
    }
    if (pathname.includes("/pacing-margin")) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/reports"
            logo={pacing_margin_logo}
            btnLabel="Pacing & Margin"
            activePage={activePage}
          />
        </>
      );
    }

    if (
      pathname.includes("/taxonomy") ||
      pathname.includes("/taxonomy-rules") ||
      pathname.includes("/create-taxonomy")
    ) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/taxonomy"
            logo={taxonomy_logo}
            btnLabel="Taxonomy Alerts"
            activePage={activePage}
          />
          <ButtonComp
            btnType="Link"
            to="/taxonomy-rules"
            logo={taxonomy_rules_logo}
            btnLabel="Taxonomy Rules"
            activePage={activePage}
          />
          {isAdmin && (
            <ButtonComp
              btnType="button" //change to Link for active button
              // to="/create-taxonomy"
              logo={create_taxonomy_logo}
              btnLabel="Create Taxonomy"
              activePage={activePage}
            />
          )}
        </>
      );
    }

    if (
      pathname.includes("/rule-library") ||
      pathname.includes("/create-rule")
    ) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/create-rule"
            logo={create_rule_logo}
            btnLabel="Create Rule"
            activePage={activePage}
          />
          <ButtonComp
            btnType="Link"
            to="/rule-library"
            logo={rules_logo}
            btnLabel="Rule Library"
            activePage={activePage}
          />
          <ButtonComp
            btnType="Link"
            to="/campaigns"
            logo={campaigns_logo}
            btnLabel="Campaigns"
            activePage={activePage}
          />
        </>
      );
    }

    if (
      pathname.includes("/connectors-library") ||
      pathname.includes("/connectors") ||
      pathname.includes("/my-connectors")
    ) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/connectors-library"
            logo={connectors_lib_logo}
            btnLabel="Connectors Library"
            activePage={activePage}
          />
          <ButtonComp
            btnType="Link"
            to="/my-connectors"
            logo={connector_logo}
            btnLabel="My Connectors"
            activePage={activePage}
          />
        </>
      );
    }

    if (
      pathname.includes("/opportunities") ||
      pathname.includes("/archive") ||
      pathname.includes("/campaigns")
    ) {
      return (
        <>
          {getHomeButton()}
          <ButtonComp
            btnType="Link"
            to="/campaigns"
            logo={campaigns_logo}
            btnLabel="Campaigns"
            activePage={activePage}
          />
          <ButtonComp
            btnType="Link"
            to="/opportunities"
            logo={opportunities_logo}
            btnLabel="Opportunities"
            activePage={activePage}
          />
          <ButtonComp
            btnType="Link"
            to="/create-rule"
            logo={create_rule_logo}
            btnLabel="Rule Library"
            activePage={activePage}
          />
          {/* <ButtonComp
            btnType="Link"
            to="/archive"
            logo={archive_logo}
            btnLabel="Archive"
            activePage={activePage}
          /> */}
        </>
      );
    }

    return <>{getHomeButton()}</>;
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-[100px] bg-[#F6F9F8] flex flex-col">
      <div className="flex flex-col flex-grow p-4 space-y-4">
        <img
          src={atomic_adds_logo}
          alt="company_logo"
          className="w-[67px] mt-12 mb-[67px]"
        />
        {/* Render buttons based on the current pathname */}
        {renderButtonsBasedOnPath()}
      </div>

      {/* {renderAdminUserToggle()} */}
      {/* Bottom buttons container */}
      <div className="flex flex-col gap-[17px] mb-12 p-4">
        <ButtonComp
          btnType="button"
          logo={logout_logo}
          btnLabel="Logout"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default Sidebar;
