import React from "react";
import { useAuth } from "../../context/AuthContext";
import { MdWavingHand } from "react-icons/md";
import { useProfile } from "../../context/ProfileContext";

const UserAvatar: React.FC = () => {
  const { isAdmin } = useProfile();
  const { fullName } = useAuth();
  return (
    <div className="flex justify-end items-center">
      <div className="flex gap-2 items-center text-sm">
        {/* Added for demo purpose */}
        <MdWavingHand size={20} color="#286043" />
        <p className="font-semibold">Hi {fullName?.split(" ")[0]}</p>
        {/* <p className="font-semibold">Hi {isAdmin ? "Admin" : "User"}</p> */}
        <div className="p-5 rounded-full bg-gray-200 w-7 h-7 flex justify-center items-center text-lg font-bold">
          {fullName?.[0]}
        </div>
      </div>
    </div>
  );
};

export default UserAvatar;
